import http from '@/common/http';
import constants from '@/config/constants';
import { isEmpty } from '@/common/validators';

// TODO: テナントテーブルが作成されたら項目追加する.
// 初期値
const initialData = {
  tenantId: null,
  tenantName: null,
  activate: null,
  isServiceProvider: null
};

export default {
  namespaced: true,
  state: {
    tenantId: initialData.tenantId,
    tenantName: initialData.tenantName,
    activate: initialData.activate,
    isServiceProvider: initialData.isServiceProvider
  },
  getters: {
    tenantId(state) {
      return state.tenantId;
    },
    tenantName(state) {
      return state.tenantName;
    },
    activate(state) {
      return state.activate;
    },
    isServiceProvider(state) {
      return state.isServiceProvider;
    }
  },
  actions: {
    /**
     * テナント取得処理
     * @param reqData リクエストデータ
     */
    async getRequest({ commit }, params) {
      return new Promise((resolve, reject) => {
        http
          .get(constants.API_TENANT_URI + '/' + params.tenantId)
          .then(response => {
            if (response) {
              params = Object.assign(params, response.data);
              commit('setTenantInfo', params);
            }
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    /**
     * テナントの初期化
     */
    clearTenant({ commit }) {
      commit('clearTenantInfo', initialData);
    }
  },
  mutations: {
    setTenantInfo(state, tenantInfo) {
      if (isEmpty(tenantInfo)) {
        return;
      }

      if ('tenantId' in tenantInfo) {
        state.tenantId = tenantInfo.tenantId;
      }
      if ('tenantName' in tenantInfo) {
        state.tenantName = tenantInfo.tenantName;
      }
      if ('activate' in tenantInfo) {
        state.activate = tenantInfo.activate;
      }
      if ('isServiceProvider' in tenantInfo) {
        state.isServiceProvider = tenantInfo.isServiceProvider;
      }
    },
    clearTenantInfo(state, initialData) {
      for (let key in initialData) {
        state[key] = initialData[key];
      }
    }
  }
};
