import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { i18n } from './plugin/i18n';
import { Amplify } from 'aws-amplify';
import constants from '@/config/constants';

const awsConfig = {
  Auth: {
    identityPoolId: constants.COGNITO_IDPOOL_ID,
    region: constants.COGNITO_REGION,
    userPoolId: constants.COGNITO_USERPOOL_ID,
    userPoolWebClientId: constants.COGNITO_CLIENT_ID
  }
};

Amplify.configure(awsConfig);

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .mount('#app');
