import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

// modules
import tenantAdministrator from './modules/tenantAdministrator';
import tenant from './modules/tenant';

export default createStore({
  modules: {
    tenantAdministrator: tenantAdministrator,
    tenant
  },
  plugins: [
    createPersistedState({
      key: 'tenantAdministrator',
      paths: ['tenantAdministrator'],
      storage: window.sessionStorage
    }),
    createPersistedState({
      key: 'tenant',
      paths: ['tenant'],
      storage: window.sessionStorage
    })
  ]
});
