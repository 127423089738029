<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  mounted() {
    document.documentElement.setAttribute('lang', this.$i18n.locale);
    document.title = this.$t('title');
    document.querySelector("meta[name='description']").setAttribute('content', this.$t('meta.description'));
    document.querySelector("meta[name='keywords']").setAttribute('content', this.$t('meta.keywords'));
  }
};
</script>

<style>
#app {
  margin: 0;
  height: 100%;
}
</style>
