/**
 * 主に環境変数の値など、システム全体で利用する定数です。
 */
export default Object.freeze({
  // アプリケーションドメイン
  APP_DOMAIN: process.env.VUE_APP_DOMAIN,

  // アプリケーションURL
  APP_URL: process.env.VUE_APP_URL,

  // API ベースURL
  API_BASE_URL: process.env.VUE_APP_API_BASE_URL,

  // API キー
  API_KEY: process.env.VUE_APP_API_KEY,

  // フロント側アプリケーションURL
  APP_FRONT_URL: process.env.VUE_APP_FRONT_URL,

  // フロント側一時機能拡張タグURL
  APP_FRONT_TAG_URL: '?tags=',

  // Cognito REGION
  COGNITO_REGION: process.env.VUE_APP_COGNITO_REGION,

  // Cognito IDPOOL_ID
  COGNITO_IDPOOL_ID: process.env.VUE_APP_COGNITO_IDPOOL_ID,

  // Cognito USERPOOL_ID
  COGNITO_USERPOOL_ID: process.env.VUE_APP_COGNITO_USERPOOL_ID,

  // Cognito CLIENT_ID
  COGNITO_CLIENT_ID: process.env.VUE_APP_COGNITO_CLIENT_ID,

  // API テナント管理者取得処理 登録・更新・取得・削除処理URL
  API_TENANT_ADMINISTRATOR_URI: '/' + process.env.VUE_APP_API_STAGE + '/tenant/tenantAdministrator',

  // API テナント管理者一覧取得処理
  API_TENANT_ADMINISTRATOR_LIST_URI: '/' + process.env.VUE_APP_API_STAGE + '/tenant/tenantAdministratorList',

  // API テナント取得処理
  API_TENANT_URI: '/' + process.env.VUE_APP_API_STAGE + '/tenant/tenant',

  // API テナント一覧取得処理
  API_TENANT_LIST_URI: '/' + process.env.VUE_APP_API_STAGE + '/tenant/tenantList',

  // API 相談窓口登録・更新・取得・削除処理URL
  API_CONSULTATION_URL: '/' + process.env.VUE_APP_API_STAGE + '/tenant/consultation',

  // API リンク情報登録・更新・取得・削除処理URL
  API_LINK_URL: '/' + process.env.VUE_APP_API_STAGE + '/tenant/link',

  // API 申請者一覧取得処理
  API_TENANT_APPLICANT_LIST_URI: '/' + process.env.VUE_APP_API_STAGE + '/tenant/applicantList',

  // API 申請者一覧承認処理
  API_TENANT_APPLICANT_LIST_APPROVE_URI: '/' + process.env.VUE_APP_API_STAGE + '/tenant/applicantListApprove',

  // API 申請者一覧否認処理
  API_TENANT_APPLICANT_LIST_DENIAL_URI: '/' + process.env.VUE_APP_API_STAGE + '/tenant/applicantListDenial',

  // API 利用者一覧取得処理
  API_TENANT_USER_LIST_URI: '/' + process.env.VUE_APP_API_STAGE + '/tenant/userList',

  // API 利用者一覧利用解除処理
  API_TENANT_USER_LIST_UNREGISTER_URI: '/' + process.env.VUE_APP_API_STAGE + '/tenant/userListUnregister',

  // API テナント情報取得・登録・更新処理URL
  API_TENANT_INFO_URL: '/' + process.env.VUE_APP_API_STAGE + '/tenant/tenantInfo',

  // API テナント契約情報取得URL
  API_TENANT_PLAN_URL: '/' + process.env.VUE_APP_API_STAGE + '/tenant/tenantPlan',

  // API テナント拡張属性情報処理・登録・削除URL
  API_TENANT_FORM_URL: '/' + process.env.VUE_APP_API_STAGE + '/tenant/tenantForm',

  // API 記事検索処理URL
  API_COLUMN_LIST_URL: '/' + process.env.VUE_APP_API_STAGE + '/tenant/columnList',

  // API 記事登録処理URL
  API_COLUMN_URL: '/' + process.env.VUE_APP_API_STAGE + '/tenant/column',

  // API カテゴリ取得処理URL
  API_CATEGORY_URL: '/' + process.env.VUE_APP_API_STAGE + '/tenant/category',

  // API コンテンツ取得処理URL
  API_CONTENT_URL: '/' + process.env.VUE_APP_API_STAGE + '/tenant/content',

  // API テンプレート取得処理URL
  API_TEMPLATE_URL: '/' + process.env.VUE_APP_API_STAGE + '/tenant/template',

  // // API コンテンツ取得処理 登録・更新・取得・削除処理URL
  API_CONTENT_EDIT_URL: '/' + process.env.VUE_APP_API_STAGE + '/tenant/contentEdit',

  // API コンテンツ一覧取得処理
  API_CONTENT_LIST_URL: '/' + process.env.VUE_APP_API_STAGE + '/tenant/contentList',

  // 管理者・運用者権限 運用者
  ADMIN_AUTHORITY_OPERATOR: 0,
  // 管理者・運用者権限 管理者
  ADMIN_AUTHORITY_ADMINISTRATOR: 1,
  // 管理者・運用者権限 サービス管理者
  ADMIN_AUTHORITY_SERVICE_PROVIDER: 2,

  // パスワード 最小文字数
  PASSWORD_MIN_NUM: 8,

  // パスワード 最大文字数
  PASSWORD_MAX_NUM: 256,

  // コンテンツ 最大数
  CONTENT_MAX_NUM: 20,

  // 一覧用 画像サイズ
  INDEX_IMG_SIZE_NUM: 80,

  // ドメイン名ラベル最大文字数
  DOMAIN_LABEL_MAX_NUM: '63',

  // システム管理者
  SYSTEM_ADMINISTRATOR: 'systemAdministrator',

  // テナント拡張属性 選択肢 最大数
  FORM_CHOICE_MAX_LIMIT_NUM: 50,

  // テナント拡張属性 選択肢 最大文字数
  FORM_CHOICE_MAX_LENGTH_NUM: 20
});
