import http from '@/common/http';
import constants from '@/config/constants';
import { isEmpty } from '@/common/validators';

// 初期値
const initialData = {
  administratorId: null,
  username: null,
  tenantId: null,
  sei: null,
  mei: null,
  seiKana: null,
  meiKana: null,
  email: null,
  employeeNnumber: null,
  mobilephone: null,
  language: null,
  isActive: null,
  authority: null,
  tenants: []
};

export default {
  namespaced: true,
  state: {
    administratorId: initialData.administratorId,
    username: initialData.username,
    tenantId: initialData.tenantId,
    sei: initialData.sei,
    mei: initialData.mei,
    seiKana: initialData.seiKana,
    meiKana: initialData.meiKana,
    email: initialData.email,
    employeeNnumber: initialData.employeeNnumber,
    mobilephone: initialData.mobilephone,
    language: initialData.language,
    isActive: initialData.isActive,
    authority: initialData.authority,
    // サービス管理者がログインした場合に必要なテナントリスト
    tenants: initialData.tenants
  },
  getters: {
    administratorId(state) {
      return state.administratorId;
    },
    username(state) {
      return state.username;
    },
    tenantId(state) {
      return state.tenantId;
    },
    sei(state) {
      return state.sei;
    },
    mei(state) {
      return state.mei;
    },
    seiKana(state) {
      return state.seiKana;
    },
    meiKana(state) {
      return state.meiKana;
    },
    email(state) {
      return state.email;
    },
    employeeNnumber(state) {
      return state.employeeNnumber;
    },
    mobilephone(state) {
      return state.mobilephone;
    },
    language(state) {
      return state.language;
    },
    isActive(state) {
      return state.isActive;
    },
    authority(state) {
      return state.authority;
    },
    tenants(state) {
      return state.tenants;
    }
  },
  actions: {
    /**
     * テナント管理者取得処理
     */
    async getRequest({ commit }) {
      return new Promise((resolve, reject) => {
        http
          .get(constants.API_TENANT_ADMINISTRATOR_URI)
          .then(response => {
            if (response) {
              commit('setTenantAdministratorInfo', response.data);
            }
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    /**
     * テナント一覧取得処理
     */
    async getTenantsRequest({ commit }) {
      return new Promise((resolve, reject) => {
        http
          .get(constants.API_TENANT_LIST_URI)
          .then(response => {
            if (response) {
              let list = [];
              response.data.forEach(item => {
                list.push({ tenantId: item.tenantId, tenantName: item.tenantName });
              });
              const tenants = { tenants: list };
              commit('setTenantAdministratorInfo', tenants);
            }
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    /**
     * テナント管理者の初期化
     */
    clearTenantAdministrator({ commit }) {
      commit('clearTenantAdministratorInfo', initialData);
    }
  },
  mutations: {
    setTenantAdministratorInfo(state, tenantAdministrator) {
      if (isEmpty(tenantAdministrator)) {
        return;
      }

      if ('administratorId' in tenantAdministrator) {
        state.administratorId = tenantAdministrator.administratorId;
      }
      if ('username' in tenantAdministrator) {
        state.username = tenantAdministrator.username;
      }
      if ('tenantId' in tenantAdministrator) {
        state.tenantId = tenantAdministrator.tenantId;
      }
      if ('sei' in tenantAdministrator) {
        state.sei = tenantAdministrator.sei;
      }
      if ('mei' in tenantAdministrator) {
        state.mei = tenantAdministrator.mei;
      }
      if ('seiKana' in tenantAdministrator) {
        state.seiKana = tenantAdministrator.seiKana;
      }
      if ('meiKana' in tenantAdministrator) {
        state.meiKana = tenantAdministrator.meiKana;
      }
      if ('email' in tenantAdministrator) {
        state.email = tenantAdministrator.email;
      }
      if ('employeeNumber' in tenantAdministrator) {
        state.employeeNumber = tenantAdministrator.employeeNumber;
      }
      if ('mobilephone' in tenantAdministrator) {
        state.mobilephone = tenantAdministrator.mobilephone;
      }
      if ('language' in tenantAdministrator) {
        state.language = tenantAdministrator.language;
      }
      if ('isActive' in tenantAdministrator) {
        state.isActive = tenantAdministrator.isActive;
      }
      if ('authority' in tenantAdministrator) {
        state.authority = tenantAdministrator.authority;
      }
      if ('tenants' in tenantAdministrator) {
        state.tenants = tenantAdministrator.tenants;
      }
    },
    clearTenantAdministratorInfo(state, initialData) {
      for (let key in initialData) {
        state[key] = initialData[key];
      }
    }
  }
};
