import { Auth } from 'aws-amplify';
import { createRouter, createWebHistory } from 'vue-router';

import constants from '@/config/constants';
import store from '@/store/index.js';

import PageNotFound from '../views/error/PageNotFound.vue';

const routes = [
  {
    path: '/',
    name: 'Top',
    component: () => import(/* webpackChunkName: "top" */ '../views/Top.vue'),
    meta: { breadcrumbName: 'top.title', requiresAuth: true },
    children: [
      {
        path: '/tenantAdmin',
        name: 'TenantAdmin',
        component: () => import(/* webpackChunkName: "tenantAdmin" */ '../views/TenantAdmin.vue'),
        meta: { breadcrumbName: 'tenantAdministration.title' },
        children: [
          {
            path: '/consultationDescription',
            name: 'ConsultationDescription',
            component: () => import(/* webpackChunkName: "consultationDescription" */ '../views/tenant/ConsultationDescription.vue'),
            meta: { breadcrumbName: 'consultationDescription.title' }
          },
          {
            path: '/consultationRegister',
            name: 'ConsultationRegisterInput',
            component: () => import(/* webpackChunkName: "consultationRegisterInput" */ '../views/tenant/ConsultationRegisterInput.vue'),
            meta: { breadcrumbName: 'consultation.register.title' }
          },
          {
            path: '/consultationEdit',
            name: 'ConsultationEditInput',
            component: () => import(/* webpackChunkName: "consultationEditInput" */ '../views/tenant/ConsultationEditInput.vue'),
            meta: { breadcrumbName: 'consultation.edit.title' }
          },
          {
            path: '/linkRegister',
            name: 'LinkRegisterInput',
            component: () => import(/* webpackChunkName: "linkRegisterInput" */ '../views/tenant/LinkRegisterInput.vue'),
            meta: { breadcrumbName: 'link.register.title' }
          },
          {
            path: '/LinkEdit',
            name: 'LinkEditInput',
            component: () => import(/* webpackChunkName: "linkEditInput" */ '../views/tenant/LinkEditInput.vue'),
            meta: { breadcrumbName: 'link.edit.title' }
          }
        ]
      },
      {
        path: '/applicantList',
        name: 'ApplicantList',
        component: () => import(/* webpackChunkName: "applicant_list" */ '../views/user/ApplicantList.vue'),
        meta: { breadcrumbName: 'applicantList.title' }
      },
      {
        path: '/userList',
        name: 'UserList',
        component: () => import(/* webpackChunkName: "user_list" */ '../views/user/UserList.vue'),
        meta: { breadcrumbName: 'userList.title' }
      },
      {
        path: '/administrator',
        name: 'AdministratorList',
        component: () => import(/* webpackChunkName: "administrator_list" */ '../views/administrator/AdministratorList.vue'),
        meta: { breadcrumbName: 'administratorList.title' },
        children: [
          {
            path: '/administrator/register',
            name: 'AdministratorRegisterInput',
            component: () => import(/* webpackChunkName: "administrator_register_input" */ '../views/administrator/AdministratorRegisterInput.vue'),
            meta: { breadcrumbName: 'administratorRegisterInput.title' }
          },
          {
            path: '/administrator/edit',
            name: 'AdministratorEdit',
            component: () => import(/* webpackChunkName: "administrator_register_input" */ '../views/administrator/AdministratorRegisterInput.vue'),
            meta: { breadcrumbName: 'administratorRegisterInput.titleEdit' },
            props: true
          }
        ]
      },
      {
        path: '/formList',
        name: 'FormList',
        component: () => import(/* webpackChunkName: "form_list" */ '../views/form/FormList.vue'),
        meta: { breadcrumbName: 'formList.title' },
        children: [
          {
            path: '/formList/register',
            name: 'FormRegisterInput',
            component: () => import(/* webpackChunkName: "form_register_input" */ '../views/form/FormRegisterInput.vue'),
            meta: { breadcrumbName: 'formRegister.title' }
          }
        ]
      },
      {
        path: '/columnList',
        name: 'ColumnList',
        component: () => import(/* webpackChunkName: "column_list" */ '../views/column/ColumnList.vue'),
        meta: { breadcrumbName: 'columnList.title' },
        children: [
          {
            path: '/column/register',
            name: 'ColumnRegisterInput',
            component: () => import(/* webpackChunkName: "column_register_input" */ '../views/column/ColumnRegisterInput.vue'),
            meta: { breadcrumbName: 'column.register.title' }
          },
          {
            path: '/column/edit',
            name: 'ColumnEditInput',
            component: () => import(/* webpackChunkName: "column_register_input" */ '../views/column/ColumnRegisterInput.vue'),
            meta: { breadcrumbName: 'column.edit.title' }
          }
        ]
      },
      {
        path: '/content',
        name: 'ContentList',
        component: () => import(/* webpackChunkName: "content_list" */ '../views/content/ContentList.vue'),
        meta: { breadcrumbName: 'contentList.title' },
        children: [
          {
            path: '/content/register',
            name: 'ContentRegisterInput',
            component: () => import(/* webpackChunkName: "content_register_input" */ '../views/content/ContentRegisterInput.vue'),
            meta: { breadcrumbName: 'contentRegisterInput.title' }
          },
          {
            path: '/content/edit',
            name: 'ContentEdit',
            component: () => import(/* webpackChunkName: "content_register_input" */ '../views/content/ContentRegisterInput.vue'),
            meta: { breadcrumbName: 'contentRegisterInput.titleEdit' }
          }
        ]
      },
      {
        path: '/changePassword',
        name: 'ChangePassword',
        component: () => import(/* webpackChunkName: "change_password" */ '../views/password/ChangePassword.vue'),
        meta: { breadcrumbName: 'changePassword.title' }
      },
      {
        path: '/error',
        name: 'Error',
        component: () => import(/* webpackChunkName: "error" */ '../views/error/Error.vue')
      }
    ]
  },
  {
    path: '/auth',
    name: 'Auth',
    redirect: '/login',
    component: () => import(/* webpackChunkName: "auth" */ '../views/login/Auth.vue'),
    meta: { requiresAuth: false },
    children: [
      {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '../views/login/Login.vue')
      },
      {
        path: '/loginError',
        name: 'LoginError',
        component: () => import(/* webpackChunkName: "error" */ '../views/error/Error.vue')
      }
    ]
  },
  { path: '/:pathMatch(.*)*', component: PageNotFound }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async to => {
  // 遷移先がログインエラー画面の場合、ログアウト処理、ストアの削除、セッションストレージの削除を行う
  if (to.name === 'LoginError') {
    await Auth.signOut();
    store.dispatch('tenantAdministrator/clearTenantAdministrator');
    store.dispatch('tenant/clearTenant');
    sessionStorage.clear();
    return;
  }

  let isLogin = false;
  await Auth.currentAuthenticatedUser()
    .then(() => {
      isLogin = true;
    })
    .catch(() => {
      isLogin = false;
    });
  if (!isLogin && to.meta.requiresAuth) {
    // 未ログインで認証が必要な画面を表示しようとした場合、遷移先をログイン画面に書き換える
    return '/login';
  } else if (isLogin && to.name === 'Login') {
    // ログイン状態かつログイン画面へ遷移する際は、認証関連の情報をクリアし遷移する。
    await Auth.signOut();
    store.dispatch('tenantAdministrator/clearTenantAdministrator');
    store.dispatch('tenant/clearTenant');
    sessionStorage.clear();
    return '/login';
  } else if (isLogin && store.getters['tenantAdministrator/administratorId'] === null) {
    // ログイン済かつストアにテナント管理者IDが無い場合、情報の取得を行う
    await store.dispatch('tenantAdministrator/getRequest').then(async () => {
      if (store.getters['tenantAdministrator/authority'] === constants.ADMIN_AUTHORITY_SERVICE_PROVIDER) {
        await store.dispatch('tenantAdministrator/getTenantsRequest');
      }
      // ログインした管理者・運用者に紐づくテナントを取得
      let tenantParameter = {
        tenantId: store.getters['tenantAdministrator/tenantId'] || ''
      };
      await store.dispatch('tenant/getRequest', tenantParameter).then(async () => {
        // テナントの状態が無効（解約）の場合
        if (store.getters['tenant/activate'] === false) {
          await Auth.signOut();
          store.dispatch('tenantAdministrator/clearTenantAdministrator');
          store.dispatch('tenant/clearTenant');
          sessionStorage.clear();
          router.push({ name: 'Login' });
        }
      });
    });
  }
});

export default router;
