<template>
  <div id="page_not_found">
    <div>404 PageNotFound</div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound'
};
</script>
